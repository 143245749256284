const contractAddresses = {
  masterChef: "0x2b2Ed985F0F6C5EB1B1942e53c24E060fE43537b",
  multiCall: "0xcD6c042a827E33967916fCB9f396904dA307e9ae",
  zap: "0x8C0Df30e63135eC82bBE0ABb8B28fEE078bAbc50",
  router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  factory: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  weth: "0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA",
  wild: "0xE9f49BE8C684A823102b33DA0cEa82c6e229F05C",
  wbnb: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  busd: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  usdt: "0x55d398326f99059fF775485246999027B3197955",
  usdc: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  nft: "0x16e209ee047B0d0fb1829Ef072EA6Ace8e6Ec463",
  usdcLp: "0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b",
  wildWbnblp: "0x244a0802b8E0cA0b77768F423124cba16f256013",
};

export default contractAddresses;
